<template>
  <component v-bind:is="currentComponent" v-on:success="stepSuccess" @cancel="$emit('cancel')" :data="data"></component>
</template>

<script>

import EditPhoneSendCode from "@/components/profile/EditPhoneSendCode";

export default {
  props:['value'],
  components: {
    EditPhoneSendCode
  },
  data() {
    return {
      data:{},
      currentComponent:'EditPhoneSendCode'
    }
  },
  methods:{
    stepSuccess(data){
      this.data = Object.assign(this.data, data);
      this.currentComponent = data.nextComponent;
    }
  },
  created() {
    this.data.mobilePhone=this.value;
  }
}
</script>