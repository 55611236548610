<template>
<form class="form" @submit.prevent="submit">
  <div class="profile__info-content-container">
    <div class="profile__info-toast">
      <div class="profile__info-toast-title part-title">{{$t('components.profile.editNotification.title')}}</div>
      <div class="profile__info-toast-subtitle text">
        {{$t('components.profile.editNotification.desc')}}
      </div>
      <div class="profile__info-toast-choice">
        <Checkbox
            :label="$t('components.profile.editNotification.allowSms')"
            v-model="model.allowSms"
        />
        <Checkbox
            :label="$t('components.profile.editNotification.allowEmail')"
          v-model="model.allowEmail"
        />
      </div>
    </div>
  </div>
  <div class="profile-separator"></div>
  <Button visual-style="fill" :loading="isLoading">{{$t('actions.save')}}</Button>
</form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props:['allowEmail','allowSms'],
  components: {
  },
  data() {
    return {
      model:{
        allowSms:false,
        allowEmail:false
      },
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.isLoading=true;

      let sendData = {};
      sendData['allowSms'] = this.model.allowSms;
      sendData['allowEmail'] = this.model.allowEmail;

      ApiService.editContact(sendData).then((resp)=>{
        this.$emit('success',resp.data);
        this.isLoading=false;
      }).catch((err)=>{
        let data = err.data;
        this.errorText = data.errorText;
        this.isLoading=false;
      });
    }
  },
  created() {
    this.model.allowEmail = this.allowEmail;
    this.model.allowSms = this.allowSms;
  }
}
</script>
