<template>
  <div class="profile__info-content" v-if="!loading">
    <div class="profile__info-content-container">
      <div class="profile-title">{{$t('profile.contact.title')}}</div>
      <div class="profile__subtitle">{{$t('profile.contact.description')}}</div>
      <div class="profile__info-social">
        <div class="profile__info-social-item">
          <div class="profile__info-social-item-title">{{$t('profile.contact.email')}}</div>
          <div class="profile__info-social-item-text">{{contact.EmailAddress}}</div>
          <button class="btn btn__secondary btn__secondary_outline-green" @click="openModalEmail=true">{{ $t('actions.edit') }}</button>
        </div>
        <div class="profile__info-social-item">
          <div class="profile__info-social-item-title">{{$t('profile.contact.phone')}}</div>
          <div class="profile__info-social-item-text">{{contact.MobilePhone}}</div>
          <button v-if="false" class="btn btn__secondary btn__secondary_outline-green" @click="openModalPhone=true">{{ $t('actions.edit') }}</button>
        </div>
      </div>
    </div>
    <div class="profile-separator"></div>

    <EditNotification
        :allowEmail="contact.AllowEmail"
        :allowSms="contact.AllowSms"
        @success="(data)=>{success(data)}"></EditNotification>
    <Dialog
        buttonsType="save"
        v-if="openModalEmail"
        @close="openModalEmail = false"
    >
      <div slot="header">
        <h3>{{$t('profile.contact.modalEditEmail')}}</h3>
      </div>
      <div slot="body">
        <EditFields
            field="emailAddress"
            label="E-mail"
            valid="email"
            :value="contact.EmailAddress"
            @cancel="openModalEmail=false"
            @success="(data)=>{success(data);openModalEmail=false;}"
        ></EditFields>
      </div>
    </Dialog>

    <Dialog
        buttonsType="save"
        v-if="openModalPhone"
        @close="openModalPhone = false"
    >
      <div slot="header">
        <h3>{{$t('profile.contact.modalEditPhone')}}</h3>
      </div>
      <div slot="body">
        <EditPhone
            :value="contact.MobilePhone"
            @cancel="openModalPhone=false"
            @success="(data)=>{success(data);openModalPhone=false;}"
        ></EditPhone>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {mapGetters,mapMutations} from "vuex";

import Dialog from '@/components/Controlls/Dialog';
import EditFields from '@/components/profile/EditFields';
import EditPhone from "@/components/profile/EditPhone";
import EditNotification from "@/components/profile/EditNotification";


export default {
  components: {
    Dialog,
    EditFields,
    EditPhone,
    EditNotification
  },
  data() {
    return {
      openModalEmail: false,
      openModalPhone: false,
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      contact: "contact/contact"
    })
  },
  methods: {
    ...mapMutations({
      setContact:'contact/setContact'
    }),
    success(data){
      this.setContact(data)
    }
  }
}
</script>
