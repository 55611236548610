<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>
      <Input
          v-model="model.phone"
          label="Телефон"
          :state="!validation.hasError('model.phone')"
          :error-text="validation.firstError('model.phone')"
          :mask="'+7 (###) ###-##-##'"
      ></Input>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">Отмена</Button>
        <Button visual-style="fill" :loading="isLoading">Сохранить</Button>
      </div>
    </div>
  </form>
</template>

<script>
import SimpleVueValidator from 'simple-vue-validator';
import ApiService from "@/services/api.service";

const Validator = SimpleVueValidator.Validator;

export default {
  props:{
    data: {
      type: [Object]
    }
  },
  components: {
  },
  data() {
    return {
      model:{
        phone:''
      },
      errorText:'',
      submitted:false,
      isLoading:false
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
            if (success) {

              this.isLoading=true;

              ApiService.changePhoneSendCode(this.model).then(()=>{



                this.isLoading=false;
              }).catch((err)=>{
                let data = err.data;
                this.errorText = data.errorText;
                this.isLoading=false;
              });

            }
          }
      )
    }
  },
  created() {
    this.model.phone=this.data.mobilePhone;
  },
  validators: {
    "model.phone": function (value) {
      if (this.submitted)
        return Validator.value(value).required("Поле обязательно для заполнения").regex(/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/, 'Не корректный номер');
    }
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>